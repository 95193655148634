import TableDropdown from "@/components/tableDropdowns/TableDropdown.vue";
import Badge, { BadgeProps, BadgeSize, BadgeColor } from "@/dsComponents/Badge";
import { AcitveProposalCompany, ActiveProposalHistory, ActiveProposalStatus, Timestamp } from "@/types";
import { ActiveProposalGroupByCompanyWorker } from "@/views/activeProposition/types";
import { textMain900 } from "@/color";
import i18n from "@/i18n";
import moment from "moment-timezone";
import { translate } from "@/helpers/commons";

export const getReasonDropdown = (typeView: "company" | "worker", data: ActiveProposalGroupByCompanyWorker | AcitveProposalCompany) => {
    const badgeProps =
        typeView === "company"
            ? {
                  componentProps: {
                      color: BadgeColor.transparent,
                      size: BadgeSize.sm,
                      icon: "user-x",
                  },
              }
            : {};

    return {
        customElement: {
            component: TableDropdown,
            props: {
                trigger: typeView === "worker" ? data.refusalReason?.reason : Badge,
                width: "300px",
                mode: "hover",
                direction: "left",
                items: [
                    { text: data.refusalReason?.reason, textStyle: "color: #00061A" },
                    { text: "Details" },
                    { text: data.refusalReason?.details, textStyle: "color: #00061A" },
                ],
                ...badgeProps,
            },
        },
    };
};

export const getWithNameDropdown = (data: AcitveProposalCompany) => {
    return {
        customElement: {
            component: TableDropdown,
            props: {
                trigger: data.name,
                mode: "hover",
                width: "300px",
                direction: "left",
                items: [{ text: "Contact" }, { text: data.contact, textStyle: "color: #00061A" }],
            },
        },
    };
};

export const getNameWithoutDropdown = (data: string) => {
    return { text: { value: data, leading: true } };
};

export const getDate = (date: Timestamp | number | null | undefined) => {
    return moment(date).format("D MMM YY");
};

export const getDateDropdown = (
    data: ActiveProposalGroupByCompanyWorker | AcitveProposalCompany,
    command: ActiveProposalStatus,
    type: "company" | "worker"
) => {
    return {
        customElement: {
            component: TableDropdown,
            props: {
                trigger: getDate(data?.history?.[command]),
                dropdownStyle: {
                    triggerTextColor: textMain900,
                    triggerTextClass: "text-xxs regular",
                    contentTextClass: "text-xxs regular",
                },
                mode: "hover",
                items: data?.history ? history(data?.history, type) : null,
            },
        },
    };
};

export const history = (history: ActiveProposalHistory, type: "company" | "worker") => {
    const historyObject = Object.keys(history).map((key) => {
        return { status: key, date: history[key as ActiveProposalStatus] };
    });

    return historyObject
        .filter((historyStatus) => type === "company" || historyStatus.status !== ActiveProposalStatus.PENDING)
        .map((historyStatus) => {
            const badgeProps: BadgeProps = {
                size: BadgeSize.sm,
                stroke: true,
            };

            switch (historyStatus.status) {
                case ActiveProposalStatus.RELAUNCHED:
                    badgeProps.color = BadgeColor.yellow;
                    break;
                case ActiveProposalStatus.TRANSFORMED:
                    badgeProps.color = BadgeColor.primary;
                    break;
                case ActiveProposalStatus.REFUSED:
                    badgeProps.color = BadgeColor.red;
                    break;
                case ActiveProposalStatus.PENDING:
                    if (type === "company") {
                        badgeProps.color = BadgeColor.neutral;
                    }
                    break;
            }

            // Traduction du label avec i18n
            badgeProps.label = translate(`page.activeProposition.status.${historyStatus.status}`);

            return {
                text: "-   " + getDate(historyStatus.date),
                textStyle: {
                    whiteSpace: "pre",
                },
                component: Badge,
                componentProps: badgeProps,
            };
        });
};
